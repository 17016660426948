<template>
    <div>
        <div :class="{ comment, answer: !!comment.parent_id }">
            <div class="comment-left">
                <div class="user-ava">
                    <img :src="comment.user.imageLink" alt=""/>
                </div>
            </div>

            <div class="comment-center">
                <div class="name-and-date">
                    <div class="user-name">
                        <h5>{{ comment.user.fullname }}</h5>
                    </div>

                    <div class="comment-date">
                        <h5>{{ comment.since_created }}</h5>
                    </div>
                </div>

                <div class="text">
                    <p>{{ comment.comment }}</p>
                </div>

                <div class="actions">
                    <div class="do-answer">
                        <svg width="30" height="30" fill="none" xmlns="http://www.w3.org/2000/svg" v-bind:svg-inline="''" v-bind:class="'do-answer-icon'" v-bind:role="'presentation'" v-bind:focusable="'false'" v-bind:tabindex="'-1'"><mask id="s2ava2kp9a" maskUnits="userSpaceOnUse" x="5" y="6" width="20" height="20"><path fill-rule="evenodd" clip-rule="evenodd" d="M24.99 8c0-1.1-.89-2-1.99-2H7c-1.1 0-2 .9-2 2v12c0 1.1.9 2 2 2h14l4 4-.01-18zM21 18H9v-2h12v2zm0-3H9v-2h12v2zm0-3H9v-2h12v2z" fill="#fff"/></mask><g mask="url(#s2ava2kp9a)"><path fill="#D8D8D8" d="M2 2h26v26H2z"/></g></svg>

                        <div class="do-answer-label text-plex13-30" @click="handleCommentButtonClick()">
                            {{ i18n.comment[$lang] }}
                        </div>
                    </div>

                    <div class="do-rate">
                        <div
                                class="do-rate-like"
                                :class="{ active: comment.isLiked === 1 }"
                                @click="like"
                        >
                            <svg width="30" height="30" fill="none" xmlns="http://www.w3.org/2000/svg" v-bind:svg-inline="''" v-bind:role="'presentation'" v-bind:focusable="'false'" v-bind:tabindex="'-1'"><mask id="r1e9lpfd7a" maskUnits="userSpaceOnUse" x="4" y="4" width="22" height="20"><path fill-rule="evenodd" clip-rule="evenodd" d="M4 24h4V12H4v12zm22-11c0-1.1-.9-2-2-2h-6.31l.95-4.57.03-.32c0-.41-.17-.79-.44-1.06L17.17 4l-6.58 6.59c-.37.36-.59.86-.59 1.41v10c0 1.1.9 2 2 2h9c.83 0 1.54-.5 1.84-1.22l3.02-7.05c.09-.23.14-.47.14-.73v-1.91l-.01-.01L26 13z" fill="#fff"/></mask><g mask="url(#r1e9lpfd7a)"><path d="M2 2h26v26H2z"/></g></svg>
                        </div>

                        <div
                                class="rate-counter text-plex13-30"
                                :class="{
                good: comment.likes_total > 0,
                bad: comment.likes_total < 0,
              }"
                        >
                            {{ comment.likes_total }}
                        </div>

                        <div
                                class="do-rate-dislike"
                                :class="{ active: comment.isLiked === -1 }"
                                @click="dislike"
                        >
                            <svg width="30" height="30" fill="none" xmlns="http://www.w3.org/2000/svg" v-bind:svg-inline="''" v-bind:role="'presentation'" v-bind:focusable="'false'" v-bind:tabindex="'-1'"><mask id="8h6yk341sa" maskUnits="userSpaceOnUse" x="4" y="6" width="22" height="20"><path fill-rule="evenodd" clip-rule="evenodd" d="M18 6H9c-.83 0-1.54.5-1.84 1.22l-3.02 7.05c-.09.23-.14.47-.14.73v1.91l.01.01L4 17c0 1.1.9 2 2 2h6.31l-.95 4.57-.03.32c0 .41.17.79.44 1.06L12.83 26l6.59-6.59c.36-.36.58-.86.58-1.41V8c0-1.1-.9-2-2-2zm4 0v12h4V6h-4z" fill="#fff"/></mask><g mask="url(#8h6yk341sa)"><path fill="#D8D8D8" d="M2 2h26v26H2z"/></g></svg>
                        </div>
                    </div>
                </div>
            </div>

            <div class="comment-right">
                <div class="comment-menu">
                    <img src="@/assets/img/icons/ic_more_horiz.svg" alt=""/>
                </div>
            </div>
        </div>

        <comment-form
                class="comment-reply-form"
                v-if="activeReplyFormParent === comment.id"
                :parentId="comment.id"
        />

        <reply-comment-list
                v-if="collection[comment.id]"
                :collection="collection"
                :comments="collection[comment.id]"
        />
    </div>
</template>

<script>
    import {mapState, mapActions} from "vuex";
    import CommentForm from "../CommentForm";

    export default {
        name: "Comment",
        components: {
            ReplyCommentList: () => import("../CommentList"),
            CommentForm,
        },
        props: {
            comment: {
                type: Object,
                required: true,
            },
            collection: {
                type: [Object, Array],
                required: true,
            },
        },
        data() {
            return {
                showReplyForm: false,
                i18n: {
                    comment: {
                        en: "Comment",
                        ru: "Комментировать",
                    },
                },
            };
        },
        computed: {
            ...mapState({
                $lang: (state) => state.$lang,
            }),
            ...mapState("comments", ["activeReplyFormParent"]),
        },
        methods: {
            handleCommentButtonClick() {
                this.$store.dispatch("comments/updateReplyFormParent", this.comment.id);
            },
            like() {
                this.$store.dispatch(
                    "comments/" + (this.comment.isLiked !== 1 ? "like" : "unlike"),
                    this.comment.id
                );
            },
            dislike() {
                this.$store.dispatch(
                    "comments/" + (this.comment.isLiked !== -1 ? "dislike" : "unlike"),
                    this.comment.id
                );
            },
        },
    };
</script>

<style scoped lang="scss">
    .comment-center {
        margin-left: 30px;
        @media only screen and (max-width: 767px) {
            margin-left: 10px;
        }
    }

    .user-ava img {
        width: 50px;
        height: 50px;
        border-radius: 50%;
        transform: translateY(-8px);
        @media only screen and (max-width: 767px) {
            width: 20px;
            height: 20px;
            transform: translateY(-4px);
        }
    }

    .name-and-date {
        display: flex;
        align-items: center;
        justify-content: flex-start;
    }

    .user-name h5 {
        font-weight: 500;
        color: #666666;
    }

    .comment-date h5 {
        font-weight: 500;
        color: #999999;
        margin-left: 16px;
        @media only screen and (max-width: 767px) {
            margin-left: 10px;
        }
    }

    .comment-menu {
        height: 26px;
        width: 26px;
        margin-left: 30px;
        cursor: pointer;
        @media only screen and (max-width: 767px) {
            margin-left: 10px;
        }
    }

    .actions {
        display: flex;
        align-items: center;
        justify-content: flex-start;
        margin-top: 15px;
        color: #d8d8d8;
        @media only screen and (max-width: 767px) {
            margin-top: 20px;
            justify-content: space-between;
            padding-right: 36px;
        }
    }

    .do-answer {
        display: flex;
        align-items: center;
        justify-content: flex-start;
        cursor: pointer;
    }

    .do-answer-icon {
        width: 26px;
    }

    .do-answer-label {
        color: #b3b3b3;
        margin-left: 17px;
    }

    .do-rate {
        display: flex;
        align-items: center;
        justify-content: flex-start;
        margin-left: 81px;
        @media only screen and (max-width: 767px) {
            margin-left: 20px;
        }
    }

    .do-rate-like,
    .do-rate-dislike {
        cursor: pointer;
        @media only screen and (max-width: 767px) {
            display: none;
        }
    }

    .do-rate-like.active {
        background-color: green;
    }

    .do-rate-dislike.active {
        background-color: red;
    }

    .rate-counter {
        color: #b6b6b6;
        margin: 0 12px;

        &.bad {
            color: red;
        }

        &.good {
            color: green;
        }

        @media only screen and (max-width: 767px) {
            font-size: 11px;
            line-height: 11px;
            margin: 0;
        }
    }

    .text {
        color: #999999;
        margin-top: 10px;
    }

    .comment {
        position: relative;
        display: flex;
        align-items: flex-start;
        justify-content: flex-start;
        margin-top: 45px;
        padding-top: 45px;
        border-top: 2px #f6f6f6 solid;

        &.answer {
            margin-left: 57px;

            &:before {
                content: "";
                position: absolute;
                z-index: 1;
                top: 45px;
                left: -45px;
                display: block;
                height: 26px;
                width: 26px;
                background: url("~@/assets/img/icons/ic_arrow_forward.svg") no-repeat center;
                background-size: 26px;
            }
        }

        @media only screen and (max-width: 767px) {
            margin: 0;
            padding: 26px 0 22px;
            &.answer {
                margin-left: 0;
                padding-left: 30px;

                &:before {
                    left: 2px;
                    top: 19px;
                }
            }
        }

        .comment-right {
            position: absolute;
            top: 30px;
            right: 0;
            @media only screen and (max-width: 767px) {
                bottom: 25px;
                top: auto;
            }
        }
    }
</style>
